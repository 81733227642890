import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import urlJoin from 'url-join';
import { formatISO, isValid as isValidDate, startOfDay, startOfTomorrow } from 'date-fns';
import { useForm, FormProvider } from 'react-hook-form';
import { ageGroups, prices, urls } from 'app-constants';
import { getCsrfToken, messages } from 'utils';
import {
  ButtonChoiceField,
  CheckboxField,
  CheckboxMultiField,
  DateField,
  parseDateFieldValue,
  formatDateFieldValue,
  reformatISO,
  EventDatesField,
  eventDatesRowDefaults,
  ImageField,
  LiveSearchSelectField,
  RichTextField,
  SelectField,
  TextField,
  URLField,
  hydrateEventDatesValue,
  dehydrateEventDatesValue,
} from 'components/formFields';
import DropdownButton from 'components/DropdownButton';
import Icon from 'components/Icon';
import LoadingOverlay from 'components/LoadingOverlay';
import PillButton from 'components/PillButton';

const EventEditForm = ({ categoryChoices, poiChoices, eventId }) => {
  const [isFetching, setIsFetching] = useState(!!eventId);
  const [apiValidationErrors, setApiValidationErrors] = useState({});
  const fieldValidator = fieldName => () => !apiValidationErrors[fieldName] || apiValidationErrors[fieldName].join(' ');

  const defaultPoi = (poiChoices || []).length === 1 ? poiChoices[0][0] : '';

  const defaultValues = {
    name: '',
    url: '',
    price_information: '',
    description: '',
    type: 'in_person',
    use_poi_times: false,
    is_global: false,
    online_url: '',
    price: '',
    event_type: null,
    date_start: null,
    date_end: null,
    dates: [{ ...eventDatesRowDefaults }],
    point_of_interest: defaultPoi,
    image: '',
    image_crop_data: null,
    age_group: [],
    recertify: false,
  };

  const formMethods = useForm({
    mode: 'onTouched',
    defaultValues,
  });
  const { formState: { errors: formErrors, isValid: formIsValid }, setValue } = formMethods;
  const formHasErrors = Object.keys(formErrors).length > 0;

  const clearForm = () => {
    formMethods.reset(defaultValues);
    setTimeout(() => formMethods.clearErrors(), 50);
  };

  const debugFillForm = useCallback(() => {
    const startDate = formatDateFieldValue(startOfTomorrow());
    const debugValues = {
      name: `Debug Event - ${(new Date()).toLocaleString()}`,
      url: 'http://example.com',
      price_information: '',
      description: 'Dolor consequat deserunt officia fugiat laboris veniam ut non irure voluptate nostrud ut enim.',
      type: 'in_person',
      use_poi_times: false,
      is_global: false,
      online_url: '',
      price: 'free',
      event_type: categoryChoices[0][0],
      date_start: null,
      date_end: null,
      dates: [
        // {
        //   ...eventDatesRowDefaults,
        //   date: startDate,
        //   startTime: '9:00 AM',
        //   endTime: '10:00 AM',
        // },
        {
          ...eventDatesRowDefaults,
          date: startDate,
          startTime: '12:00 PM',
          endTime: '1:00 PM',
          frequency: 'daily',
        },
      ],
      point_of_interest: 1197,
      image: '',
      image_crop_data: null,
      age_group: ['all_ages'],
      recertify: false,
    };

    formMethods.reset(debugValues);
    setTimeout(() => formMethods.clearErrors(), 50);
  }, [categoryChoices.length]);

  const handleKeyDown = useCallback(evt => {
    if (evt.key === '1' && evt.metaKey && evt.shiftKey) {
      evt.preventDefault();
      debugFillForm();
    }
  }, [debugFillForm]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  const [eventData, setEventData] = useState(null);

  const hydrateEventData = data => {
    /* eslint-disable camelcase */
    let { is_online, is_in_person, dates, ...rest } = data;

    let type;
    if (is_online) type = 'online';
    if (is_in_person) type = 'in_person';
    if (is_online && is_in_person) type = 'hybrid';

    dates = rest.use_poi_times ? [] : hydrateEventDatesValue(dates, rest.date_start || rest.created).map(vals => ({ ...eventDatesRowDefaults, ...vals }));

    // ignore timezones for these values
    ['date_start', 'date_end'].forEach(field => {
      if (rest[field]) {
        rest[field] = reformatISO(rest[field]);
      }
    });

    return { ...rest, type, dates };
    /* eslint-enable */
  };

  const fetchEventData = () => {
    if (!eventId) return null;
    const url = urlJoin(urls.apiEditEventBase, `${eventId}/`);
    setIsFetching(true);

    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(data => {
        setEventData(hydrateEventData(data));
        setIsFetching(false);
      })
      .catch(err => {
        console.error(err);
        setIsFetching(false);
      });
  };

  useEffect(() => {
    clearForm();
    fetchEventData();
  }, [eventId]);

  useEffect(() => {
    if (eventData) {
      // logObj(eventData);
      formMethods.reset(eventData);
      setTimeout(formMethods.trigger, 100);
    }
  }, [eventData]);

  const saveEventData = payload => {
    const url = eventId ? urlJoin(urls.apiEditEventBase, `${eventId}/`) : urls.apiAddEvent;
    const method = eventId ? 'PUT' : 'POST';

    setApiValidationErrors({});
    setIsFetching(true);

    fetch(url, {
      credentials: 'include',
      method,
      headers: {
        'X-CSRFToken': getCsrfToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(response => {
        setIsFetching(false);

        if (response.status === 400) {
          // validation errors
          response.json().then(setApiValidationErrors);
        } else if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          response.json().then(data => {
            const { absoluteUrl } = camelize(data);
            const msg = `Successfully ${eventId ? 'updated' : 'created'} event "${data.name}".`;
            messages.add(messages.success, msg, () => window.location.href = absoluteUrl);
          });
        }
        return response;
      })
      .catch(err => {
        console.error(err);
        setIsFetching(false);
      });
  };

  useEffect(() => {
    if (Object.keys(apiValidationErrors).length > 0) {
      formMethods.trigger();
    }

    const subscription = formMethods.watch((value, { name, type }) => {
      if (apiValidationErrors[name]) {
        setApiValidationErrors(oldState => {
          const { [name]: _, ...rest } = oldState;
          return rest;
        });
        setTimeout(() => formMethods.trigger(name), 100);
      }
    });

    return () => subscription.unsubscribe();
  }, [JSON.stringify(apiValidationErrors)]);

  const onSubmit = data => {
    const apiData = Object.entries(data).reduce((result, [key, value]) => {
      switch (key) {
        case 'type':
          result.is_in_person = ['hybrid', 'in_person'].includes(value);
          result.is_online = ['hybrid', 'online'].includes(value);
          break;
        case 'date_start':
          // fall through
        case 'date_end':
          result[key] = value && formatISO(parseDateFieldValue(value));
          break;
        case 'dates':
          result[key] = data.use_poi_times ? [] : dehydrateEventDatesValue(value);
          break;
        default:
          result[key] = value;
      }

      return result;
    }, {});

    saveEventData(apiData);
  };

  const typeVal = formMethods.watch('type');
  const usePoiTimesVal = formMethods.watch('use_poi_times');
  const datesVal = formMethods.watch('dates');

  useEffect(() => {
    if (!usePoiTimesVal && !datesVal.length) {
      setValue('dates', [{ ...eventDatesRowDefaults }]);
    }
  }, [usePoiTimesVal]);

  const today = useMemo(() => startOfDay(new Date()));

  const startDateVal = formMethods.watch('date_start');
  let startDateValAsDate = parseDateFieldValue(startDateVal);
  startDateValAsDate = isValidDate(startDateValAsDate) ? startDateValAsDate : null;
  const minStartDate = eventId ? undefined : today;
  const minEndDate = startDateValAsDate || today;
  useEffect(() => {
    formMethods.trigger('date_end');
  }, [startDateVal]);

  const initialDataLoaded = !eventId || !!eventData;

  return (
    <div className="edit-form-container">
      {!!eventId && <LoadingOverlay show={isFetching} align="top" />}
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <div className="container-fluid">
            <h2 className="fw-bold mb-4">Event Basic Information</h2>

            <div className="row mb-3">
              <TextField
                name="name"
                label="Event Name"
                required
                maxLength={1024}
                validate={fieldValidator('name')}
              />
            </div>

            <div className="row mb-4">
              <URLField
                name="url"
                label="Event URL"
                required
                validate={fieldValidator('url')}
              />
            </div>

            <div className="row mb-4">
              <SelectField
                name="event_type"
                label="Event Category"
                required
                choices={categoryChoices.map(([val, label]) => [val, label])}
                validate={fieldValidator('event_type')}
              />
            </div>

            <hr className="my-5" />

            <h2 className="fw-bold mb-4">Venue Information</h2>

            <div className="row mb-4">
              {poiChoices ? (
                <SelectField
                  name="point_of_interest"
                  label="Venue Name"
                  required
                  choices={poiChoices.map(([val, label]) => [val, label])}
                  validate={fieldValidator('point_of_interest')}
                />
              ) : (
                <LiveSearchSelectField
                  name="point_of_interest"
                  objectType="poi"
                  label="Venue Name"
                  required
                  validate={fieldValidator('point_of_interest')}
                />
              )}
            </div>

            <div className="row mb-4">
              <ButtonChoiceField
                name="type"
                choices={[
                  ['in_person', 'In Person'],
                  ['online', 'Online'],
                  ['hybrid', 'Hybrid'],
                ]}
                required
              />
            </div>

            {typeVal !== 'in_person' && (
              <>
                <div className="row mb-3">
                  <URLField
                    name="online_url"
                    label="Live Stream URL"
                    required={typeVal !== 'in_person'}
                    validate={fieldValidator('online_url')}
                  />
                </div>

                <div className="row mb-4">
                  <CheckboxField
                    name="is_global"
                    label="Global Live Stream"
                  />
                </div>
              </>
            )}

            <hr className="my-5" />

            <h2 className="fw-bold mb-4">Event Details</h2>

            {initialDataLoaded && (
              <div className="row mb-4">
                <div className="col">
                  <ImageField
                    name="image"
                    label=""
                    cropDataName="image_crop_data"
                    initialImage={eventData && eventData.image_url}
                    initialCropData={eventData && eventData.image_crop_data}
                    validate={fieldValidator('image')}
                  />
                </div>
              </div>
            )}

            <div className="row mb-4">
              <div className="col">
                <RichTextField
                  name="description"
                  label="Event description"
                  required
                  maxLength={2000}
                  validate={fieldValidator('description')}
                />
              </div>
            </div>

            <hr className="my-5" />

            <h2 className="fw-bold mb-4">Date & Time</h2>

            <div className="row mb-4">
              <CheckboxField
                name="use_poi_times"
                label="Same as venue’s hours of operation"
              />
            </div>

            {usePoiTimesVal ? (
              <div className="row mb-4">
                <div className="col">
                  <DateField
                    name="date_start"
                    label="Start Date"
                    minDate={minStartDate}
                    validate={fieldValidator('date_start')}
                  />
                </div>
                <div className="col">
                  <DateField
                    name="date_end"
                    label="End Date"
                    minDate={minEndDate}
                    // shownDate={minEndDate}
                    validate={fieldValidator('date_end')}
                  />
                </div>
              </div>
            ) : (
              <EventDatesField name="dates" allowAnyDate={!!eventId} />
            )}

            <hr className="my-5" />

            <h2 className="fw-bold mb-4">Price & Age</h2>

            <div className="row mb-4">
              <SelectField
                name="price"
                label="Price"
                choices={prices.map(([val, label]) => [val, label])}
                validate={fieldValidator('description')}
              />
            </div>

            <div className="row mb-3">
              <div className="col">
                <TextField
                  name="price_information"
                  label="Additional pricing information"
                  multiline
                  maxLength={500}
                  validate={fieldValidator('price_information')}
                />
              </div>
            </div>

            <div className="row mb-4">
              <CheckboxMultiField
                name="age_group"
                label="Age Groups"
                required
                helpText="Check all that apply."
                choices={ageGroups.map(([val, label]) => [val, label])}
                validate={fieldValidator('age_group')}
              />
            </div>

            {!!eventId && (
              <>
                <hr className="my-5" />
                <h2 className="fw-bold mb-4">Editor Tools</h2>
                <div className="row mb-2">
                  <CheckboxField
                    name="recertify"
                    label="Recertify based on this edit"
                  />
                </div>
              </>
            )}

          </div>

          <div className="edit-form-controls">
            <PillButton
              text={eventId ? 'Cancel' : 'Discard'}
              className="text-danger clear"
              prependIcon={['far', 'times']}
              onClick={() => window.location.href = (eventData.absolute_url || urls.events)} // TODO - redirect to dashboard
            />
            <div className="d-flex align-items-center">
              {formHasErrors && <div className="text-sm text-danger me-2">Please correct form errors before submitting.</div>}

              {!!eventId && (
                <DropdownButton
                  buttonClassName="subtle-focus circle me-2"
                  menuAnchor="bottomRight"
                  prependIcon={<Icon i={['far', 'ellipsis-v']} />}
                  appendIcon={null}
                >
                  <div className="link-list py-3">
                    <a
                      href={`${urls.deleteEventBase}${eventId}/`}
                      className="link-list-item px-3"
                    >
                      <span className="text-danger">Delete</span>
                    </a>
                  </div>
                </DropdownButton>
              )}

              <input type="submit" value={eventId ? 'Save' : 'Publish'} className="btn-z primary" disabled={isFetching || !formIsValid} />
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

EventEditForm.propTypes = {
  categoryChoices: PropTypes.arrayOf(PropTypes.array),
  poiChoices: PropTypes.arrayOf(PropTypes.array),
  eventId: PropTypes.number,
};

export default EventEditForm;
