module.exports = Object.assign({}, {
  urls: {
    apiBase: '/events/api/',
    apiPoiList: '/events/api/v1/poi/',
    addWidget: '/account/widgets/add/',
    editWidget: '/account/widgets/edit/',
    widgets: '/account/widgets/',
    widgetMetricsBase: '/metrics/widget/',
    widgetEmbedBase: '/widget/',
    events: '/events/',
    eventIcalendarBase: '/icalendar/event/',
    termsOfUse: '/terms-of-use/',
    venues: '/venues/',

    // Auth
    login: '/login/',
    loginAjax: '/events/api/v1/auth/login/',
    loginSocial: {
      apple: '/auth/apple/login/',
      google: '/auth/google/login/',
      twitter: '/auth/twitter/login/',
      facebook: '/auth/facebook/login/',
    },
    logout: '/auth/logout/',
    register: '/register/',
    registerAjax: '/events/api/v1/register/',
    passwordReset: '/account/password_reset/',

    // User
    userData: '/events/userdata/',
    account: '/account/',
    postMessage: '/message/',

    // Editor
    editorProfileBase: '/editor/',
    editorTimesheets: '/editor/timesheets/',
    addEventLegacy: '/add/event/',
    addPOILegacy: '/add/poi/',
    addGlobalLiveStream: '/add/livestream/',

    dashboard: '/manage/dashboard/',
    apiProducerContactForm: '/manage/support/submit/',
    addEvent: '/manage/events/add/',
    editEventBase: '/manage/events/edit/',
    addPOI: '/manage/venues/add/',
    geocodeAddress: '/manage/geocode/',
    apiEditorEventList: '/manage/events/list/',
    apiAddEvent: '/events/api/events/add/',
    apiEditEventBase: '/events/api/events/edit/',
    deleteEventBase: '/delete/event/',
    apiAddVenue: '/events/api/poi/add/',
    apiEditVenueBase: '/events/api/poi/edit/',
    deleteVenueBase: '/delete/poi/',
    apiWidgetBase: '/events/api/widgets/',
    apiWidgetList: '/events/api/widgets/',

    // Analytics reports
    gaSummaryStats: '/analytics/report-data/summary/',
    gaEventCardImpressions: '/analytics/report-data/event-card-impressions/',
    gaPoiCardImpressions: '/analytics/report-data/poi-card-impressions/',
    gaPoiCombined: '/analytics/report-data/poi-combined/',

    // Event/POI live search
    eventDetailBase: '/events/api/v1/events/',
    poiDetailBase: '/events/api/v1/poi/',
    eventLivesearchBase: '/events/api/v1/events/livesearch/',
    poiLivesearchBase: '/events/api/v1/poi/livesearch/',

    // Saved
    savedAddresses: '/saved/addresses/',
    addAddress: '/saved/addresses/add/',
    editAddressBase: '/saved/addresses/edit/',

    savedEventList: '/events/api/saved_events/',
    savedEventIdsList: '/events/api/saved_events_ids/',
    savedPoiList: '/events/api/saved_pois/',
    savedSearchList: '/events/api/v1/saved_searches/',
    savedSearchBase: '/events/api/v1/saved_searches/',
    toggleSavedEventBase: '/events/api/saved_events/event/',
    toggleSavedPoiBase: '/events/api/saved_pois/poi/',
    savedAddressList: '/events/api/v1/search_addresses/',
    savedAddressBase: '/events/api/v1/search_addresses/',
    savedSearchMailPreviewBase: '/mailchimp/savedsearch/',
    savedSearchMailSendTest: '/mailchimp/savedsearch_send_test/',

    // Search result ads
    searchAd: '/events/ads/user/',
    searchAdPing: '/events/ads/ping/',

    // Reports
    editorReport: '/reports/editor/',
    userReport: '/reports/user/',
    poiReportQA: '/reports/poi_qa/',
    lhReport: 'http://lh.zeitcaster.net',
    explorer: '/explorer',

    // Admin
    wagtailAdmin: '/wagtail/',
    djangoAdmin: '/django/',
  },
  typeIcons: {
    /* eslint-disable quote-props */
    'Art, Dance & Culture': 'paint-brush',
    'Children': 'child',
    'Classes, Workshops & Talks': 'chalkboard-teacher',
    'Comedy': 'laugh-beam',
    'Community': 'users',
    'Fairs & Festivals': 'grin-stars',
    'Film': 'film',
    'Food & Farmers Markets': 'utensils',
    'Games & Hobbies': 'chess',
    'Health & Fitness': 'running',
    'Literary & Book Clubs': 'book',
    'Music': 'music',
    'Nature & Outdoors': 'tree',
    'Religion & Spirituality': 'pray',
    'Seniors': 'hands-heart',
    'Sports': 'trophy',
    'Support Groups': 'hand-holding-heart',
    'Theater': 'theater-masks',
    // fallback value
    default: ['faz', 'events'],
    /* eslint-enable */
  },
  typeBadgeColors: {
    /* eslint-disable quote-props */
    'Literary & Book Clubs': 'purple',
    'Film': 'purple',

    'Sports': 'zc',
    'Theater': 'zc',

    'Health & Fitness': 'blue',
    'Seniors': 'blue',

    'Classes, Workshops & Talks': 'green',
    'Support Groups': 'green',

    'Fairs & Festivals': 'yellow',
    'Comedy': 'yellow',

    'Nature & Outdoors': 'orange',
    'Children': 'orange',

    'Community': 'red',
    'Food & Farmers Markets': 'red',

    'Music': 'brown',
    'Art, Dance & Culture': 'brown',

    'Religion & Spirituality': 'platinum',
    'Games & Hobbies': 'platinum',
    /* eslint-enable */
  },
  dateRangeLabels: {
    today: 'Today',
    tomorrow: 'Tomorrow',
    'this-weekend': 'This weekend',
    'next-3': 'Next 3 days',
    'next-7': 'Next 7 days',
    'next-30': 'Next 30 days',
    custom: 'Custom',
  },
  badgeColorClasses: [
    'purple',
    'zc',
    'blue',
    'green',
    'yellow',
    'orange',
    'red',
    'brown',
    'platinum',
    'clear',
  ],
  badgeDefaultColorClass: 'yellow',
  // Filter configurations
  ageGroups: [
    ['all_ages', 'All Ages', 'All Ages'],
    ['baby', 'Baby 0–1', 'Babies'],
    ['toddler', 'Toddler 2–4', 'Toddlers'],
    ['child', 'Child 5–9', 'Children'],
    ['preteen', 'Preteen 10–12', 'Preteens'],
    ['teen', 'Teen 13–17', 'Teens'],
    ['adult', 'Adult 18+', 'Adults'],
    ['senior', 'Senior', 'Seniors'],
  ],
  prices: [
    ['free', 'Free', 'Free'],
    ['low', '$ (Under $25)', '$'],
    ['medium', '$$ ($25–$75)', '$$'],
    ['high', '$$$ (Over $75)', '$$$'],
  ],
  distanceOptions: [5, 10, 25, 50, 100],
  defaultDistance: {
    event: 50,
    poi: 25,
  },
  // TODO - this comes from django settings
  defaultLocations: [
    { id: 'pittsfield', name: 'Pittsfield, MA', lon: -73.2534800, lat: 42.4498420 },
    { id: 'adams', name: 'Adams, MA', lon: -73.121035, lat: 42.621518 },
    { id: 'alford', name: 'Alford, MA', lon: -73.414010, lat: 42.236215 },
    { id: 'becket', name: 'Becket, MA', lon: -73.057561, lat: 42.276944 },
    { id: 'bennington', name: 'Bennington, VT', lon: -73.197147, lat: 42.878388 },
    { id: 'dalton', name: 'Dalton, MA', lon: -73.168756, lat: 42.473050 },
    { id: 'egremont', name: 'Egremont, MA', lon: -73.429079, lat: 42.190534 },
    { id: 'great-barrington', name: 'Great Barrington, MA', lon: -73.3628880, lat: 42.1939230 },
    { id: 'hancock', name: 'Hancock, MA', lon: -73.310148, lat: 42.548157 },
    { id: 'hudson', name: 'Hudson, NY', lon: -73.790800, lat: 42.252917 },
    { id: 'kinderhook', name: 'Kinderhook, NY', lon: -73.695717, lat: 42.395047 },
    { id: 'lee', name: 'Lee, MA', lon: -73.248198, lat: 42.305269 },
    { id: 'lenox', name: 'Lenox, MA', lon: -73.2829460, lat: 42.3578866 },
    { id: 'litchfield', name: 'Litchfield, CT', lon: -73.188791, lat: 41.747414 },
    { id: 'manchester', name: 'Manchester, VT', lon: -73.068129, lat: 43.164059 },
    { id: 'norfolk', name: 'Norfolk, CT', lon: -73.196373, lat: 41.990604 },
    { id: 'north-adams', name: 'North Adams, MA', lon: -73.1144640, lat: 42.6996190 },
    { id: 'northampton', name: 'Northampton, MA', lon: -72.639713, lat: 42.324999 },
    { id: 'sheffield', name: 'Sheffield, MA', lon: -73.3537930, lat: 42.1100570 },
    { id: 'stockbridge', name: 'Stockbridge, MA', lon: -73.307689, lat: 42.281473 },
    { id: 'williamstown', name: 'Williamstown, MA', lon: -73.208755, lat: 42.714790 },
  ],
  imageSpecs: [
    { id: 'primary', label: 'Primary', aspectRatio: 2 },
    // { id: 'thumbnail', label: 'Thumbnail', aspectRatio: 1 },
  ],
});
