import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { useToggle } from 'hooks';
import useErrorOrHelpText from './useErrorOrHelpText';
import HelpPopup from 'components/HelpPopup';

const TextField = ({
  name,
  label,
  helpText,
  helpPopupContent,
  required = false,
  disabled = false,
  multiline = false,
  minLength,
  maxLength,
  validate,
  registerOpts = {},
  formMethods,
}) => {
  const { register, watch } = formMethods || useFormContext();
  const { errorOrHelpText, hasError } = useErrorOrHelpText(name, helpText, formMethods);

  const lengthMessage = (minLength && maxLength)
    ? `This field must be between ${minLength} and ${maxLength} characters.`
    : null;
  let { onBlur, ...inputProps } = register(name, {
    required: { value: required, message: 'This field is required.' },
    disabled,
    minLength: { value: minLength, message: lengthMessage || `This field must be at least ${minLength} character${minLength === 1 ? '' : 's'}.` },
    maxLength: { value: maxLength, message: lengthMessage || `This field is limited to ${maxLength} characters.` },
    validate,
    ...registerOpts,
  });
  const val = watch(name) || '';

  const [isFocused, toggleFocused] = useToggle(false);
  const handleFocus = () => toggleFocused(true);
  const handleBlur = evt => {
    toggleFocused(false);
    onBlur(evt);
  };

  const wrapperClasses = classNames({
    'z-form-input-wrap': !multiline,
    'z-form-textarea-wrap': multiline,
    focused: isFocused,
    required,
    'has-val': !!val,
    invalid: hasError,
  });

  inputProps = {
    className: classNames({
      'z-form-input': !multiline,
      'z-form-textarea': multiline,
      'z-form-disabled': disabled,
    }),
    onFocus: handleFocus,
    onBlur: handleBlur,
    ...inputProps,
  };

  return (
    <div className={wrapperClasses}>
      <div style={{ position: 'relative' }}>
        {multiline ? <textarea {...inputProps} /> : <input type="text" {...inputProps} />}
        {label && (
          <label className={classNames(`z-form-${multiline ? 'textarea' : 'input'}-label`, !!disabled && 'z-form-disabled')}>
            {label}
          </label>
        )}
        {!!helpPopupContent && <HelpPopup>{helpPopupContent}</HelpPopup>}
      </div>
      <div className="z-form-hint-container">
        {errorOrHelpText}
        {maxLength && <div className={classNames('z-form-char-count', val.length > maxLength && 'text-danger')}>{val.length} / {maxLength}</div>}
      </div>
    </div>
  );
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helpText: PropTypes.string,
  helpPopupContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  validate: PropTypes.func,
  // Pass through additional options to react-hook-form `register` method:
  // https://react-hook-form.com/api/useform/register/
  registerOpts: PropTypes.object,
  formMethods: PropTypes.object,
};

export default TextField;
