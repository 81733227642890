import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { badgeColorClasses, badgeDefaultColorClass } from 'app-constants';

const Badge = ({ text, type, colorClass, className, style = {}, onClick }) => {
  colorClass = colorClass || badgeDefaultColorClass;
  const handleClick = evt => onClick && onClick(evt);

  return (
    <div
      className={classNames('badge', colorClass, onClick && 'clickable', className)}
      style={style}
      data-badge-type={type}
      data-badge-value={text}
      onClick={handleClick}
    >
      {text}
    </div>
  );
};

Badge.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  colorClass: PropTypes.oneOf(badgeColorClasses),
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

export default Badge;
