import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useBootstrapBreakpoint, useHistory } from 'hooks';
import { useFilterContext } from 'context';
import DropdownButton from 'components/DropdownButton';
import Icon from 'components/Icon';

export const ORDER_DEFAULT = null;
export const ORDER_DATE = 'dt_next';
export const ORDER_DISTANCE = 'distance';
export const ORDER_NAME = 'name';
export const ORDER_PRICE_ASC = 'price';
export const ORDER_PRICE_DESC = '-price';
export const ORDER_EVENT_COUNT = '-num_events_future';

const EVENT_ORDER_OPTIONS = [
  [ORDER_DEFAULT, 'Featured'],
  [ORDER_DATE, 'Date'],
  [ORDER_DISTANCE, 'Distance'],
  [ORDER_PRICE_ASC, 'Lowest Price'],
  [ORDER_PRICE_DESC, 'Highest Price'],
];

const POI_ORDER_OPTIONS = [
  [ORDER_DEFAULT, 'Distance'],
  [ORDER_NAME, 'Name'],
  [ORDER_EVENT_COUNT, 'Event Count'],
];

const OrderingMenu = ({ className, buttonClassName, objectType = 'event', menuAnchor }) => {
  const ORDER_OPTIONS = {
    event: EVENT_ORDER_OPTIONS,
    poi: POI_ORDER_OPTIONS,
  }[objectType];

  const { location: { queryParams }, updateQueryParams } = useHistory();
  const ordering = (queryParams && queryParams.ordering) || ORDER_DEFAULT;

  const { updateFilterContext } = useFilterContext();
  useEffect(() => {
    updateFilterContext({ ordering });
  }, [ordering]);

  const orderingMenuRef = useRef();
  const handleOrderChange = (evt, ordering) => {
    evt.preventDefault();
    updateQueryParams({ ordering });
    orderingMenuRef.current.close();
  };

  const isMobile = useBootstrapBreakpoint('down-md');
  const activeOption = ORDER_OPTIONS.find(([val]) => val === ordering);
  const activeLabel = activeOption ? activeOption[1] : '(unknown)';

  return (
    <DropdownButton
      ref={orderingMenuRef}
      text={!isMobile && <span>Sort: {activeLabel}</span>}
      appendIcon={isMobile ? <Icon i={['faz', 'sort']} /> : undefined}
      className={className}
      buttonClassName={classNames('borderless', isMobile ? 'circle' : 'clear text-tertiary', buttonClassName)}
      menuAnchor={menuAnchor || (isMobile ? 'left' : 'right')}
    >
      <div className="link-list py-3" style={{ width: 200 }}>
        {ORDER_OPTIONS.map(([value, label]) => (
          <a
            key={value}
            href={`#${label}`}
            className={classNames('link-list-item', 'px-3', ordering === value && 'color-primary')}
            onClick={evt => handleOrderChange(evt, value)}
          >
            <Icon i={['far', 'check']} style={{ visibility: ordering === value ? 'visible' : 'hidden' }} />
            <span>{label}</span>
          </a>
        ))}
      </div>
    </DropdownButton>
  );
};

OrderingMenu.propTypes = {
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  objectType: PropTypes.oneOf(['event', 'poi']),
  menuAnchor: PropTypes.oneOf(['left', 'right']),
};

export default OrderingMenu;
