import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import camelize from 'camelize';
import queryString from 'query-string';
import { urls } from 'app-constants';

export default function useReportData (reportName, startDate, endDate, poiId = null) {
  let url;

  const baseUrl = {
    summaryStats: urls.gaSummaryStats,
    poiCombined: urls.gaPoiCombined,
  }[reportName];

  if ([baseUrl, startDate, endDate].every(Boolean)) {
    url = baseUrl;

    if (poiId) {
      url += `${poiId}/`;
    }

    url += '?' + queryString.stringify({
      start_date: format(startDate, 'yyyy-MM-dd'),
      end_date: format(endDate, 'yyyy-MM-dd'),
    });
  }

  const [data, setData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const fetchReportData = () => {
    if (!url) return null;
    setIsFetching(true);
    fetch(url)
      .then(response => {
        setIsFetching(false);
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(data => setData(camelize(data)))
      .catch(err => {
        setIsFetching(false);
        console.error(err);
      });
  };

  useEffect(() => {
    fetchReportData();
  }, [url]);

  return { data, isFetching };
}
