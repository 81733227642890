import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import queryString from 'query-string';
import { parseISO, isValid } from 'date-fns';
import { typeIcons, typeBadgeColors, prices } from 'app-constants';
import { useResizeDetector, useTrackImpression, useWidgetId } from 'hooks';
import AddToCalendar from 'components/AddToCalendar';
import Badge from 'components/Badge';
import BookmarkToggleButton from 'components/BookmarkToggleButton';
import EventDateSummary from 'components/EventDateSummary';
import EventTypeBadge from 'components/EventTypeBadge';
import Flag from 'components/Flag';
import Icon from 'components/Icon';

const EventCard = ({
  className,
  style,
  useCompactStyle = false,
  smallIcon = false,
  hidePoiInfo = false,
  hideShareButton = true,
  hideBookmarkButton = false,
  hideCalendarButton = true,
  hideFooter = false,
  renderActionButtons,
  id,
  name,
  eventType,
  description,
  nextEventOccurrence,
  occurences,
  absoluteUrl,
  ageGroup,
  isOnline,
  isInPerson,
  price,
  pointOfInterest,
  imagePrimary,
  imageThumbnail,
  highlightClass,
  isBookmarked,
  searchParams,
  onSetFilter,
  onMouseEnter,
  onMouseLeave,
}) => {
  const { width, ref } = useResizeDetector({ handleHeight: false });
  const widgetId = useWidgetId();

  const trackerRef = useTrackImpression('event_card_impression', {
    eventName: name,
    eventId: id,
    venueName: pointOfInterest.name,
    venueId: pointOfInterest.id,
    widgetId,
  });

  let destUrl = absoluteUrl;
  if (widgetId) {
    destUrl += `?widget=${widgetId}`;
    if (searchParams && searchParams.ordering) {
      const searchString = encodeURIComponent(queryString.stringify(Object.entries(searchParams).reduce((result, [key, value]) => {
        if (['ordering', 'page'].includes(key)) {
          result[key] = value;
        }
        return result;
      }, {})));
      destUrl += `&search=${searchString}`;
    }
  } else if (searchParams) {
    const searchString = encodeURIComponent(queryString.stringify(searchParams, {
      arrayFormat: 'bracket',
      skipNull: true,
      skipEmptyString: true,
    }));
    destUrl += `?search=${searchString}`;
  }

  const handleCardClick = () => window.location.href = destUrl;

  const handleTypeBadgeClick = evt => {
    onSetFilter({ event_types: [eventType.id] });
    evt.stopPropagation();
  };
  const handleOnlineBadgeClick = evt => {
    onSetFilter({ is_online: 't' });
    evt.stopPropagation();
  };
  const handleInPersonBadgeClick = evt => {
    onSetFilter({ is_in_person: 't' });
    evt.stopPropagation();
  };
  const handleAgeBadgeClick = (evt, value) => {
    onSetFilter({ age_groups: [value] });
    evt.stopPropagation();
  };
  const handlePriceBadgeClick = (evt, value) => {
    onSetFilter({ prices: [value] });
    evt.stopPropagation();
  };

  const iconType = typeIcons[eventType.name] || typeIcons.default;


  let startTimestamp;
  let endTimestamp;

  if (nextEventOccurrence) {
    [startTimestamp, endTimestamp] = nextEventOccurrence.timestamps[0];
  } else {
    ({ timestampStart: startTimestamp, timestampEnd: endTimestamp } = occurences[0] || {});
  }

  const priceOption = prices.find(([val]) => val === price.value);
  const priceLabel = price && (priceOption ? priceOption[2] : price.label);

  const startTime = parseISO(startTimestamp);
  let endTime = parseISO(endTimestamp);
  if (!isValid(startTime)) return null; // short circuit if event has no date.
  if (!isValid(endTime)) endTime = null;

  const shareButton = null; // TODO

  const calendarButton = !hideCalendarButton && (
    <AddToCalendar
      showIcon
      buttonText=""
      buttonClassName="clear subtle-focus borderless circle ms-3"
      title={name}
      description={description}
      location={`${pointOfInterest.name}, ${pointOfInterest.shortAddress}`}
      startDate={startTime}
      endDate={endTime}
      eventId={id}
    />
  );

  const bookmarkButton = !hideBookmarkButton && !widgetId && (
    <BookmarkToggleButton
      className="ms-3"
      iconOnly
      objectType="event"
      objectId={id}
    />
  );

  const actionButtons = typeof renderActionButtons === 'function'
    ? renderActionButtons({ shareButton, calendarButton, bookmarkButton })
    : <>{shareButton}{calendarButton}{bookmarkButton}</>;

  const fullWidthImage = (width && width < 500) || false;
  const image = imagePrimary || pointOfInterest.imagePrimary;
  const iconContainerStyle = {
    backgroundImage: image ? `url('${image}')` : 'unset',
  };
  if (fullWidthImage) {
    iconContainerStyle.height = Math.round(width / 2);
  }
  const iconContainerClasses = classNames({
    'lc-icon': true,
    sm: smallIcon || useCompactStyle,
    'full-width': fullWidthImage,
  });

  const isFeatured = highlightClass === 'highlight';

  return (
    <div
      ref={ref}
      id={`event-card-${id}`}
      className={classNames('listings-card', 'event-card', className)}
      style={style}
      onClick={handleCardClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-event-id={id}
      data-event-name={name}
      data-poi-id={pointOfInterest.id}
      data-poi-name={pointOfInterest.name}
      data-widget-id={widgetId}
    >
      <section ref={trackerRef} className="lc-top">
        <div
          className={iconContainerClasses}
          style={iconContainerStyle}
        >
          {!image && (
            <Icon
              className={classNames('event-type-icon', typeBadgeColors[eventType.name])}
              i={iconType}
              size={(smallIcon || useCompactStyle) ? '3x' : '4x'}
            />
          )}
          <a href={destUrl} />
          {isFeatured && <Flag text="Featured" className={fullWidthImage ? '' : 'sm'} />}
        </div>

        <div className={classNames('lc-main')}>
          <div>
            <h5 className={classNames('mb-3', useCompactStyle && 'h6')}>
              <a href={destUrl}>{name}</a>
            </h5>

            {!hidePoiInfo && (
              <div className="mb-2 lh-sm d-flex align-items-center">
                <div className="me-2 d-flex justify-content-center" style={{ flex: '0 0 20px', color: '#cacaca' }}>
                  <Icon i="map-marker-alt" size="sm" />
                </div>
                <div>{pointOfInterest.name}</div>
              </div>
            )}

            <EventDateSummary
              start={startTime}
              end={endTime}
              timeZone={isOnline ? null : pointOfInterest.timeZone}
            />
          </div>
        </div>

        <div className="lc-right" onClick={evt => evt.stopPropagation()}>
          <div className="d-flex align-items-center">
            {actionButtons}
          </div>
        </div>
      </section>

      {!hideFooter && (
        <footer>
          <div className="d-flex flex-wrap ms-n1 mb-n2 me-2">
            <EventTypeBadge
              eventTypeName={eventType.name}
              className="d-block mx-1 mb-2"
              onClick={onSetFilter && handleTypeBadgeClick}
            />
            {isOnline && (
              <Badge
                text="Online"
                type="In-Person/Online"
                colorClass="green"
                className="d-block mx-1 mb-2"
                onClick={onSetFilter && handleOnlineBadgeClick}
              />
            )}
            {isInPerson && (
              <Badge
                text="In-Person"
                type="In-Person/Online"
                colorClass="purple"
                className="d-block mx-1 mb-2"
                onClick={onSetFilter && handleInPersonBadgeClick}
              />
            )}
            {ageGroup.map(({ label, value }) => (
              <Badge
                key={value}
                text={label}
                type="Age Group"
                colorClass="yellow"
                className="d-block mx-1 mb-2"
                onClick={onSetFilter && (evt => handleAgeBadgeClick(evt, value))}
              />
            ))}
          </div>
          {price && (
            <Badge
              key={price.value}
              text={priceLabel}
              type="Price"
              colorClass="clear"
              className="d-block mx-1 mb-2"
              onClick={onSetFilter && (evt => handlePriceBadgeClick(evt, price.value))}
            />
          )}
        </footer>
      )}
    </div>
  );
};

EventCard.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  /** Set true to display compact card style, without type icon */
  useCompactStyle: PropTypes.bool,
  smallIcon: PropTypes.bool,
  hidePoiInfo: PropTypes.bool,
  hideShareButton: PropTypes.bool,
  hideBookmarkButton: PropTypes.bool,
  hideCalendarButton: PropTypes.bool,
  hideFooter: PropTypes.bool,
  renderActionButtons: PropTypes.func,
  id: PropTypes.number,
  name: PropTypes.string,
  eventType: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  description: PropTypes.string,
  nextEventOccurrence: PropTypes.shape({
    eventDate: PropTypes.string,
    times: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    timestamps: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  }),
  occurences: PropTypes.arrayOf(PropTypes.shape({
    timestampStart: PropTypes.string,
    timestampEnd: PropTypes.string,
  })),
  absoluteUrl: PropTypes.string,
  ageGroup: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  isOnline: PropTypes.bool,
  isInPerson: PropTypes.bool,
  price: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  pointOfInterest: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    absoluteUrl: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    countryCode: PropTypes.string,
    timeZone: PropTypes.string,
    lat: PropTypes.number,
    lon: PropTypes.number,
    shortAddress: PropTypes.string,
    imagePrimary: PropTypes.string,
    imageThumbnail: PropTypes.string,
  }),
  imagePrimary: PropTypes.string,
  imageThumbnail: PropTypes.string,
  highlightClass: PropTypes.oneOf(['recurring', 'single', 'highlight', 'promoted', 'cancelled', 'postponed']),
  isBookmarked: PropTypes.bool,
  searchParams: PropTypes.object,
  onSetFilter: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

export default EventCard;
